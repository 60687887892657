<template>
  <div class="base-input">
    <label :for="name" :cy="name">{{ label || name }}</label>
    <input
      :id="name"
      :type="type || 'text'"
      :value="value"
      @input="handleInput"
      :placeholder="placeholder || label || name"
    />
    <span v-show="validate">{{ validationMsgs[rule] }}</span>
  </div>
</template>

<script setup>
  const props = defineProps({
    name: String,
    label: String,
    type: String,
    placeholder: String,
    value: String,
    validate: Boolean,
    rule: String,
  });
  const validationMsgs = {
    required: "This field is required",
  };
  const emits = defineEmits(["input"]);

  function handleInput(ev) {
    emits("input", { name: props.name, value: ev.target.value });
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .base-input {
    @media only screen and (min-width: 0) {
      label {
        margin-bottom: 6px;
        display: block;
        font-size: 1.6rem;
      }
      span {
        margin-top: 4px;
        margin-bottom: 14px;
        display: inline-block;
        color: $red;
        font-size: 1.4rem;
      }
      input,
      textarea {
        width: 100%;
        padding: 8px 0 8px 12px;
        font-size: 1.6rem;
        border: 1px solid lightgray;
        border-radius: 4px;
      }
      textarea {
        width: 100%;
        min-height: 80px;
      }
    }
  }
</style>
