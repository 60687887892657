export const state = function () {
  return {
    company: {
      name: "TS",
    },
  };
};

export const mutations = {};

export const actions = {};
