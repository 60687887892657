import { createStore } from "vuex";
import * as auth from "./modules/auth";
import * as settings from "./modules/settings";
import * as member from "./modules/member";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    settings,
    member,
  },
});
