<template>
  <label class="base-checkbox pointer">
    <input
      type="checkbox"
      class="pointer"
      :checked="checked"
      @change="handleChange"
    />
    <span>{{ label || name }}</span>
  </label>
</template>

<script setup>
  const props = defineProps({
    name: String,
    label: String,
    checked: Boolean,
    validate: Boolean,
    rule: String,
  });

  const emits = defineEmits(["change"]);

  function handleChange(ev) {
    emits("change", { name: props.name, checked: ev.target.checked });
  }
</script>

<style lang="scss">
  .base-checkbox {
    font-size: 1.6rem;
    margin-right: 24px;
    input {
      margin-right: 10px;
      transform: scale(1.3);
    }
  }
</style>
