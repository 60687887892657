import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

const routes = [
  {
    path: "/",
    redirect: "add-member",
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
  {
    path: "/add-member",
    name: "add-member",
    meta: { auth: false },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AddMember.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: () => import(/* webpackChunkName: "about" */ "../views/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
  routes,
});

router.beforeEach(async ({ params, meta, name: route }, from, next) => {
  if (route === "login") {
    return next();
  }
  meta?.auth && !store.state.auth.user?.name
    ? await store.dispatch("get_user", next)
    : next();
});

export default router;
