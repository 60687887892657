import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BaseInput from "@/components/BaseInput";
import BaseCheckBoxGroup from "@/components/BaseCheckBoxGroup";
import BaseCheckBox from "@/components/BaseCheckBox";
import "./styles/helper-classes.scss";
import "./styles/typography.scss";
import "./styles/base.scss";

const app = createApp(App);

app.component("BaseInput", BaseInput);
app.component("BaseCheckBoxGroup", BaseCheckBoxGroup);
app.component("BaseCheckBox", BaseCheckBox);

app.use(store).use(router).mount("#app");
