<template>
  <div class="base-checkbox-group">
    <span class="block m-b-8">{{ label || name }}</span>
    <div class="flex">
      <BaseCheckBox
        v-for="({ name, label }, index) in options"
        @change="handleChange"
        :name="name"
        :label="label"
        :key="index"
      />
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    name: String,
    label: String,
    options: Array,
  });

  function handleChange({ name, checked }) {
    console.log(name, checked);
  }
</script>

<style lang="scss">
  .base-checkbox-group {
    font-size: 1.6rem;
  }
</style>
